<div class="carousel">
  <ng-container *ngFor="let slide of images; let i = index">
    <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" />
    <p *ngIf="slide.showTitle && i === currentSlide">{{slide.title}}</p>
  </ng-container>

  <!-- controls -->
  <button mat-icon-button class="control prev" (click)="onPreviousClick()">
    <span class="material-icons-outlined">
      chevron_left
    </span>
  </button>
  <button mat-icon-button class="control next" (click)="onNextClick()">
    <span class="material-icons-outlined">
      chevron_right
    </span>
  </button>
</div>
