<div class="is-floating-label" [class.active]="input.value.length > 0">
  <input id="search-bar" autocomplete="off" placeholder="Hledat..." [(ngModel)]="searchInput" #input (input)="filterSearch(input.value)" name="text">
  <div class="results" *ngIf="results.length > 0 && input.value.length > 0">
    <ul>
      <li *ngFor="let res of results">
        <a routerLink="/areal">{{res}}</a>
      </li>
    </ul>
  </div>
</div>
