<header>
  <div class="content">
    <h2>Rezervace rekreačních zařízení, areálů a aktivit.</h2>
    <app-search-bar></app-search-bar>
  </div>
</header>
<div class="recent-areas">
  <h2 class="center-line">Naposledy přidané</h2>
  <div class="container">
    <ul class="area-wrapper">
      <li class="large">
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-1.jpg">
          <p>Fotbalové hřiště</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-2.jpg">
          <p>Obec</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-3.jpg">
          <p>Obec</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-4.jpg">
          <p>Lovecký zámeček</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-5.jpg">
          <p>Mladočov chata</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-6.jpg">
          <p>Mladočov budova</p>
        </a>
      </li>
      <li class="large">
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-7.jpg">
          <p>Mladočov stany</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-8.jpeg">
          <p>Tramtáryje pergola</p>
        </a>
      </li>
      <li class="large">
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-9.jpg">
          <p>Tramtáryje chata</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-2.jpg">
          <p>Obec</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-3.jpg">
          <p>Obec</p>
        </a>
      </li>
      <li>
        <a routerLink="/areal">
          <img src="../../../assets/images/areas/area-detail-4.jpg">
          <p>Lovecký zámeček</p>
        </a>
      </li>
    </ul>
  </div>
</div>
