<nav>
  <div class="container">
    <div class="logo">
      <a routerLink="/">
        <img src="../../../assets/images/logo-square.svg" alt="resevato logo">
        <p>Reservato</p>
      </a>
    </div>
    <div class="search-bar" *ngIf="router.url != '/'">
      <app-search-bar></app-search-bar>
    </div>
    <div class="links">
      <ul>
        <li>
          <a routerLink="/areal">Areály</a>
        </li>
        <li>
          <a routerLink="/areal">Kraje</a>
        </li>
        <li>
          <button mat-raised-button color="primary" class="border-button-mat" routerLink="/areal">Přidat se</button>
        </li>
        <li>
          <button mat-icon-button>
            <span class="material-icons-outlined">
              person
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div class="operators">
      <button mat-icon-button color="primary">
        <span class="material-icons-outlined">
          search
        </span>
      </button>
      <button mat-icon-button color="primary">
        <span class="material-icons-outlined">
          menu
        </span>
      </button>
    </div>
  </div>
</nav>
