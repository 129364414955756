<app-area-detail-side-menu></app-area-detail-side-menu>
<div class="area-detail" id="informations">
  <div class="carousel">
    <app-slider [images]="areaDetailData.areaImages"></app-slider>
  </div>
  <div class="area-info">
    <div class="info-heading">
      <h1 class="center-line">{{areaDetailData.title}}</h1>
      <a [href]="areaDetailData.villageMaps" target="_blank">{{areaDetailData.villageName}}</a>
    </div>
    <div class="info-body">
      <ul>
        <li>
          Kapacita {{areaDetailData.capacityPeople}} lidí
        </li>
        <li>
          Rozměry {{areaDetailData.sizeMeters}} metrů čtverečních
        </li>
        <li *ngIf="areaDetailData.optionsCart.length > 0">
          Možnost dokoupit služby
        </li>
      </ul>
      <div class="area-price">
        <button (click)="bindCart()">REZERVOVAT</button>
        <div class="price-info">
          <h2>{{areaDetailData.price}}kč / den</h2>
          <p>+ {{areaDetailData.price / 100 * 40}}kč provozní poplatek</p>
        </div>
      </div>
    </div>
    <div class="other-services">
      <span class="material-icons-outlined">
        smoking_rooms
      </span>
      <span class="material-icons-outlined">
        signal_cellular_alt
      </span>
      <span class="material-icons-outlined">
        local_parking
      </span>
      <span class="material-icons-outlined">
        pets
      </span>
      <span class="material-icons-outlined">
        location_on
      </span>
    </div>
  </div>
</div>
<div class="area-blocks" id="contact">
  <div class="container">
    <div class="area-block">
      <div class="block-heading">
        <h2 class="side-line">Kontakt</h2>
      </div>
      <ul>
        <li>
          <p>Správce: {{areaDetailData.manager}}</p>
        </li>
        <li>
          <p>Telefon: <a [href]="'tel:' + areaDetailData.managerContactPhone">{{areaDetailData.managerContactPhone}}</a>
          </p>
        </li>
        <li>
          <p>Email: <a
              [href]="'mailto:' + areaDetailData.managerContactEmail">{{areaDetailData.managerContactEmail}}</a></p>
        </li>
        <li>
          <p>Adresa: {{areaDetailData.areaAdress}}</p>
        </li>
      </ul>
    </div>
    <div class="area-block">
      <div class="block-heading">
        <h2 class="side-line">O areálu</h2>
      </div>
      <p>{{areaDetailData.htmlContent}}</p>
    </div>
  </div>
</div>
<div class="area-map" id="location">
  <h2 class="center-line">Lokace místa</h2>
  <iframe frameBorder="0" [src]="saveIframe"></iframe>
</div>
<app-free-terms-detail></app-free-terms-detail>
<app-cart [optionsToCart]="areaDetailData.optionsCart"
  [price]="areaDetailData.price +  (areaDetailData.price / 100 * 40)" *ngIf="showCart"></app-cart>
<div class="overlay-cart" *ngIf="showCart" (click)="showCart = false"></div>
<button *ngIf="showCart" class="close-cart" mat-icon-button (click)="showCart = false">
  <span class="material-icons-outlined">
    close
  </span>
</button>
