import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-detail-side-menu',
  templateUrl: './area-detail-side-menu.component.html',
  styleUrls: ['./area-detail-side-menu.component.scss'],
})
export class AreaDetailSideMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.onWindowScroll();
  }

  actualActive: number = 0;

  scrollTo(destination: any) {
    var el = document.getElementById(destination);
    if (el != null) {
      window.scrollTo({
        top: el.offsetTop,
        behavior: 'smooth',
      });
    }
    el = null;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const windowTop = -document.body.getBoundingClientRect().top + 20;
    var el0 = document.getElementById('informations');
    var el1 = document.getElementById('contact');
    var el2 = document.getElementById('location');
    var el3 = document.getElementById('terms');

    if(el0 != undefined && el1 != undefined && el2 != undefined && el3 != undefined){
      if(windowTop >= el0.offsetTop){
        this.actualActive = 0;
      }
      if(windowTop >= el1.offsetTop){
        this.actualActive = 1;
      }
      if(windowTop >= el2.offsetTop){
        this.actualActive = 2;
      }
      if(windowTop >= el3.offsetTop){
        this.actualActive = 3;
      }
    }

  }
}
