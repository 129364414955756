<div class="area-side-menu">
  <ul>
    <li [class.active]="actualActive == 0">
      <button (click)="scrollTo('informations')">
        Informace
      </button>
    </li>
    <li [class.active]="actualActive == 1">
      <button (click)="scrollTo('contact')">
        Kontakt
      </button>
    </li>
    <li [class.active]="actualActive == 2">
      <button (click)="scrollTo('location')">
        Lokace
      </button>
    </li>
    <li [class.active]="actualActive == 3">
      <button (click)="scrollTo('terms')">
        Volné termíny
      </button>
    </li>
  </ul>
</div>
