<div class="terms" id="terms">
  <div class="container">
    <h2 class="side-line">Volné termíny</h2>
    <table class="table table-bordered table-responsive-sm" id="calendar">
      <thead>
      <tr>
          <th>Sun</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
      </tr>
      </thead>

      <tbody id="calendar-body">

      </tbody>
  </table>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</div>
