<div class="slider">
  <ng-container *ngFor="let img of images; let i = index">
    <a *ngIf="i == 0" (click)="scaleSliderBind()" class="slider-item active">
      <img [src]="img.src" alt="">
    </a>
    <a *ngIf="i != 0" (click)="scaleSliderBind()" class="slider-item">
      <img [src]="img.src" alt="">
    </a>
  </ng-container>

  <ol class="slide-indicators">
    <li class="slide-indicator" *ngFor="let i of images"></li>
  </ol>
  <span class="slider-control-prev" role="button">
    <button mat-icon-button color="accent">
      <span class="material-icons-outlined">
        chevron_left
      </span>
    </button>
  </span>
  <span class="slider-control-next" role="button">
    <button mat-icon-button color="accent">
      <span class="material-icons-outlined">
        chevron_right
      </span>
    </button>
  </span>
</div>
<div *ngIf="scaleSlider" class="scale-img">
  <button mat-icon-button (click)="scaleSliderBind()">
    <span class="material-icons-outlined">
      close
    </span>
  </button>
  <app-zoom-slider [images]="images" [currentSlide]="currentIndex"></app-zoom-slider>
</div>
