<div class="cart">
  <h3 class="center-line">KOŠÍK</h3>
  <div class="is-floating-label" [class.active]="input.value.length > 0">
    <input #input id="email" placeholder="Váš email" name="email" type="text">
  </div>
  <div class="options-cart">
    <h3 class="center-line">Doplňkové služby</h3>
    <div class="option-cart" *ngFor="let opt of optionsToCart">
      <p>{{opt.optionTitle}} - {{opt.optionsPrice}}kč ({{opt.count}})</p>
      <div class="is-floating-label" [class.active]="input.value.length > 0">
        <input [(ngModel)]="opt.selectedCount" [max]="opt.count" [min]="0" value="0" type="number">
      </div>
    </div>
  </div>
  <div class="final-price">
    <h2>{{recalcPrice()}}kč</h2>
  </div>
</div>
