import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AreaImages } from 'src/app/views/area-detail/area-detail.component';

@Component({
  selector: 'app-zoom-slider',
  templateUrl: './zoom-slider.component.html',
  styleUrls: ['./zoom-slider.component.scss'],
})
export class ZoomSliderComponent implements OnInit {
  @Input() images: AreaImages[] = [];
  @Input() currentSlide: number = 0;

  constructor() {}

  ngOnInit(): void {}

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.images.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.images.length ? 0 : next;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
      this.onPreviousClick();
    }
    if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
      this.onNextClick();
    }
  }
}
