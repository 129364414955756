import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  searchInput: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  results: string[] = [];
  thingsToFilter: string[]= ['Pardubický kraj', 'Tramtáryje', 'Sál Janov', 'Svitavy', 'Praha'];

  filterSearch(inputValue: string){
    let inputNormalized = inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    this.results = !this.thingsToFilter
    ? []
    : inputNormalized.length > 0
      ?  this.thingsToFilter.filter(d => d.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(inputNormalized))
      : this.thingsToFilter;
  }

}
