import { Component, Input, OnInit } from '@angular/core';
import { CartOptions } from 'src/app/views/area-detail/area-detail.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  todayDate = new Date();
  @Input() optionsToCart: CartOptions[] = [];
  @Input() price: number = 0;
  constructor() {}

  ngOnInit(): void {}

  recalcPrice() {
    let optionsTotal = 0;
    optionsTotal = this.optionsToCart.map(function(a) {return a.selectedCount * a.optionsPrice;})
    .reduce(function(a,b) {return a + b;});
    return this.price + optionsTotal;
  }
}
