import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-free-terms-detail',
  templateUrl: './free-terms-detail.component.html',
  styleUrls: ['./free-terms-detail.component.scss'],
})
export class FreeTermsDetailComponent implements OnInit {
  today = new Date();
  currentMonth: number = 0;
  currentYear: number = 0;

  months = [
    'Led',
    'Úno',
    'Bře',
    'Dub',
    'Kvě',
    'Čvn',
    'Čvc',
    'Srp',
    'Zář',
    'Říj',
    'Lis',
    'Pro',
  ];

  constructor() {}

  ngOnInit(): void {
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.showCalendar(this.currentMonth, this.currentYear);
  }

  next() {
    this.currentYear =
      this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    this.showCalendar(this.currentMonth, this.currentYear);
  }

  previous() {
    this.currentYear =
      this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
    this.showCalendar(this.currentMonth, this.currentYear);
  }

  showCalendar(month: number, year: number) {
    let firstDay = new Date(year, month).getDay();

    const tbl = document.getElementById('calendar-body'); // body of the calendar

    // creating all cells
    let date = 1;
    for (let i = 0; i < 6; i++) {
      // creates a table row
      let row = document.createElement('tr');

      //creating individual cells, filing them up with data.
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          var cell = document.createElement('td');
          var cellText = document.createTextNode('');
          cell.appendChild(cellText);
          row.appendChild(cell);
        } else if (date > this.daysInMonth(month, year)) {
          break;
        } else {
          cell = document.createElement('td');
          cellText = document.createTextNode(date.toString());
          if (
            date === this.today.getDate() &&
            year === this.today.getFullYear() &&
            month === this.today.getMonth()
          ) {
            cell.classList.add('bg-info');
          } // color today's date
          cell.appendChild(cellText);
          row.appendChild(cell);
          date++;
        }
      }
      if (tbl != undefined) {
        tbl.appendChild(row);
        console.log(row)
      }
    }
  }
  daysInMonth(iMonth: any, iYear: any) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }
}
