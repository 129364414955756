import { Component, OnInit } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
interface AreaInfo {
  title: string;
  villageName: string;
  villageMaps: string;
  capacityPeople: string;
  sizeMeters: number;
  areaAdress: string;
  htmlContent: string;
  iframeMap: string;
  dateTerms: AreaTerms[];
  areaImages: AreaImages[];
  manager: string;
  managerContactPhone: string;
  managerContactEmail: string;
  optionsCart: CartOptions[];
  price: number;
}

export interface AreaImages {
  title: string;
  src: string;
  showTitle: boolean;
}

interface AreaTerms {
  start: string;
  end: string;
}

export interface CartOptions {
  optionTitle: string;
  count: number;
  optionsPrice: number;
  selectedCount: number;
}

@Component({
  selector: 'app-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss'],
})
export class AreaDetailComponent implements OnInit {
  saveIframe: any = null;
  areaDetailData: AreaInfo = {
    title: 'Fotbalové hřiště',
    villageName: 'Janov u Litomyšle',
    villageMaps: 'https://mapy.cz/s/pazederedu',
    capacityPeople: '50',
    areaAdress: 'Janov 295, 569 55 Janov, Pardubický kraj, Česko',
    sizeMeters: 120,
    htmlContent:
      'Areál se nachází na začátku obce, dole v údolí od Strakova. V areálu se nachází fotbalové hřiště, dětské hřiště a v zadní části další fotbalové hřiště pro děti. V budově je zařízena eletřina, voda, toalety, sprchy a velký sál, kde se nachází pípa na čepování.',
    iframeMap:
      'https://frame.mapy.cz/turisticka?id=9328661&q=janov+u+litomy%C5%A1le&source=addr&x=16.3796121&y=49.8528792&z=17',
    manager: 'Obec Janov',
    managerContactPhone: '461 639 206',
    managerContactEmail: 'obec@janov-sy.cz',
    price: 1000,
    areaImages: [
      {
        title: 'Letecký pohled na areál',
        src: '../../../assets/images/areas/area-detail-1.jpg',
        showTitle: true,
      },
      {
        title: 'Letecký pohled na obec',
        src: '../../../assets/images/areas/area-detail-2.jpg',
        showTitle: true,
      },
      {
        title: '',
        src: '../../../assets/images/areas/area-detail-3.jpg',
        showTitle: false,
      },
      {
        title: '',
        src: '../../../assets/images/areas/area-detail-4.jpg',
        showTitle: false,
      },
    ],
    optionsCart: [
      {
        optionTitle: 'Živá hudba',
        count: 1,
        optionsPrice: 2400,
        selectedCount: 0,
      },
      {
        optionTitle: 'Lavice pro 6 lidí',
        count: 15,
        optionsPrice: 100,
        selectedCount: 0,
      },
    ],
    dateTerms: [
      { start: '2021-08-16T22:00:00.000Z', end: '2021-08-18T22:00:00.000Z' },
    ],
  };
  showCart: boolean = false;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.saveIframe = this.returnSaveUrl(this.areaDetailData.iframeMap);
  }

  bindCart() {
    this.showCart = true;
  }

  returnSaveUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
